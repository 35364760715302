<template>
  <div class="StepGroupQuizView">
    <StepBackground :image="course.bannerImage" />

    <ProgressBar :progress="progress" />

    <div class="header">
      <Lives
        v-if="remainingLives > -1"
        :remaining="remainingLives" />
      <div class="spacer" />

      <!-- <Countdown
        v-if="currentQuestion"
        class="countdown"
        :value="countdown"
      /> -->

      <div class="spacer" />
      <div
        class="backButton --global-clickable"
        @click="gotoGroupQuizIntro()">
        <svgicon icon="close" />
      </div>
    </div>

    <QuizHost
      v-if="session"
      :session="session" />
  </div>
</template>

<script>
import store from '@/store';
import ThemeMixin from '../../theme-mixin';
import ProgressBar from '@/components/ProgressBar';
import QuizHost from '@/modules/quiz/components/QuizHost';
import Lives from '@/modules/games-shared/components/Lives';

import QuizSession from '@/modules/quiz/quiz-session';

import StepBackground from '../../components/StepBackground';

// import Countdown from './Countdown'
import quizHelper from '@/modules/course/quiz-helper';

export default {
    components: { StepBackground, ProgressBar, QuizHost, Lives },
    mixins: [ThemeMixin],
    props: {
        courseId: {
            type: String,
            required: true
        },
        stepGroupId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            loading: true,
            remainingLives: -1,
            progress: 0,
            session: null
        };
    },
    computed: {
        stepGroup() {
            return this.$store.state.moduleCourse.stepGroups[this.stepGroupId];
        },
        course() {
            return this.$store.state.moduleCourse.courses[this.courseId];
        }
        // countdown () {
        //   if (this.currentQuestion) {
        //     return this.currentQuestion.controller.maxTime - this.currentQuestion.controller.usedTime
        //   } else {
        //     return 0
        //   }
        // }
    },
    async mounted() {
        this.loading = false;

        const quizState = this.$store.getters['moduleCourse/getStepGroupQuizState'](this.stepGroupId);
        const dataProvider = await quizHelper.createQuestionProvider(this.stepGroup.quiz, {
            stepGroupId: this.stepGroupId,
            quizState
        });

        this.session = new QuizSession(dataProvider, { lives: 3 });
        this.session.on('update', this.onUpdate);
        this.session.on('end', this.onEnd);
        this.onUpdate();
    },
    beforeDestroy() {
        clearInterval(this.countdownId);
    },
    methods: {
        onUpdate() {
            this.progress = this.session.correctCount / this.session.totalCount;
            this.remainingLives = this.session.lives;
        },
        onEnd() {
            this.$store.dispatch('moduleCourse/registerStepGroupQuizResult', {
                stepGroupId: this.stepGroupId,
                summary: this.session.getSummary()
            });
            this.gotoGroupQuizIntro();
        },
        gotoGroupQuizIntro() {
            this.$router.push({
                name: 'StepGroupQuizIntroView',
                params: { courseId: this.courseId, stepGroupId: this.stepGroupId }
            });
        }
    },
    async beforeRouteEnter(to, from, next) {
        await store.dispatch('moduleCourse/loadCourseFull', { courseId: to.params.courseId });
        next();
    }
};
</script>

<style lang="scss" scoped>
.StepGroupQuizView {
    display: flex;
    flex-direction: column;
}

.header {
    position: relative;
    color: rgba(white, 0.9);
    height: $topBarHeight;
    display: flex;
    align-items: center;
}
.backButton {
    padding: 0 1em;
    height: $topBarHeight;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    .svg-icon {
        $size: 0.8em;
        width: $size;
        height: $size;
        fill: rgba(white, 0.9);
    }
}
.spacer {
    flex: 1;
}

.Lives {
    margin-left: 2em;
}

.countdown {
    margin-left: 1em;
}

.QuizHost {
    flex: 1;
}

@include media('>=mobile') {
    .backButton {
        right: 1em;
    }
}
@include media('<mobile') {
    .Lives {
        margin-left: 1em;
    }
}
</style>
